import React, { useState, useEffect,useRef } from "react";
import GoogleAnalyticCard from "../../../Components/Dashboard/GoogleAnalyticCard";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import CashFlowChart from "./CashFlowChart";
import "react-toastify/dist/ReactToastify.css";
import "./Analytical.css";
import { ProgressBar } from "react-bootstrap";
import AddCircleIcon from "@mui/icons-material/AddCircle";


const Analytical = (props) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  });

 


  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.pathname);
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  const { navigation } = props;
  const handleBillClick = () => {
    navigation.navigate("bills");
  };
  const [lineChartData, setLineChartData] = useState({
    xAxisData: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    yAxisData: [],
    seriesData: [],
  });

  const fetchData = async () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);
    const jwtToken = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(BASE_URL + "/fetchForGraph", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ uniqueId: decryptedUniqueId }),
      });
      const data = await response.json();
      const salesData = Object.values(data).map((month) =>
        parseFloat(month.totalSales)
      ); // Extracting total sales and parsing to float
      setLineChartData((prevState) => ({
        ...prevState,
        seriesData: salesData,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [invoiceCount, setInvoiceCount] = useState("0");
  const [purchaseCount, setPurchaseCount] = useState("0");
  const [itemCount, setItemCount] = useState("0");
  const [customerCount, setCustomerCount] = useState("0");
  const [currentValue, setCurrentValue] = useState("0");
  const [overValue, setOverValue] = useState("0");
  const [currentValueBill, setCurrentValueBill] = useState(0);
  const [overValueBill, setOverValueBill] = useState("0");
  const overValueFloat = parseFloat(overValue);
  const currentValueFloat = parseFloat(currentValue);
  const totalUnpaid = overValueFloat + currentValueFloat;
  const overValueFloatBill = parseFloat(overValueBill);
  const currentValueFloatBill = parseFloat(currentValueBill);
  const totalUnpaidBill = overValueFloatBill + currentValueFloatBill;

  const fetchInvoiceCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const invoiceCount = data.responseMessage1;
          setInvoiceCount(invoiceCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchPurchaseCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchPurchaseOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromDate: "",
        toDate: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const purchaseCount = data.responseMessage1;
          setPurchaseCount(purchaseCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchCustomerCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/getAllCustomerName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const customerCount = data.responseMessage1;
          setCustomerCount(customerCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchItemCount = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/getAllItemData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, vendorId: "" }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          const itemCount = data.rowCount;
          setItemCount(itemCount);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchAmountDue = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/totalReceivables`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCurrentValue(data.tocurrect);
          setOverValue(data.tooverDue);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  const fetchBillAmountBill = () => {
    const encryptedUniqueId = localStorage.getItem("uniqueId1");
    const decryptedUniqueId = AES.decrypt(
      encryptedUniqueId,
      "1234567812345678"
    ).toString(enc.Utf8);

    const jwtToken = localStorage.getItem("jwtToken");

    fetch(BASE_URL + `/fetchTotalPaybles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        uniqueId: decryptedUniqueId,
        fromData: "",
        toDate: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.responseCode === "200") {
          setCurrentValueBill(data.current);
          setOverValueBill(data.overDue);
        } else {
          console.error("Failed to fetch Customer Name:", data.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching Customer Name:", error);
      });
  };

  useEffect(() => {
    fetchInvoiceCount();
    fetchPurchaseCount();
    fetchCustomerCount();
    fetchItemCount();
    fetchAmountDue();
    fetchBillAmountBill();
  }, []);

  const currentPercentage = ((currentValue / totalUnpaid) * 100).toFixed(2); // Limit to 2 decimal places

  const currentPercentageBill = (
    (currentValueFloatBill / totalUnpaidBill) *
    100
  ).toFixed(2);

  useEffect(() => {
    const isFirstTime = sessionStorage.getItem("hasSeenAlert") === null;
    if (isFirstTime) {
      const timer = setTimeout(() => {
        sessionStorage.setItem("hasSeenAlert", "true");
      }, 15000);
      return () => clearTimeout(timer);
    } else {
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
  };

  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen4, setIsDropdownOpen4] = useState(false);
  const dropdownRef3 = useRef(null);
  const dropdownRef4 = useRef(null);

  const toggleDropdown3 = () => {
    setIsDropdownOpen3(!isDropdownOpen3);
  };

  const toggleDropdown4 = () => {
    setIsDropdownOpen4(!isDropdownOpen4);
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsDropdownOpen2(false);
      }

      if (
        dropdownRef3.current &&
        !dropdownRef3.current.contains(event.target)
      ) {
        setIsDropdownOpen3(false);
      }
      if (
        dropdownRef4.current &&
        !dropdownRef4.current.contains(event.target)
      ) {
        setIsDropdownOpen4(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

    return (
    <>
    <div className="head-dash">
        <div className="dash-row-head">
          <div className="head-box1">
            <h5>Welcome, CodeWorld</h5>
            {/* <p>Demo Org</p> */}
          </div>
          <div className="head-box2">
            ZARP India <img src="indian-flag.jpg" alt="flag" style={{width:"20px",height:"13px"}}></img> Helpline: <b>+91 9429691595</b> <br />
            <p>
              Mon - Sat • 11:00 AM - 7:00 PM <br />
              मराठी, हिन्दी, English
            </p>
          </div>
        </div>
        <div className="dash-btn">
          <button className="dash-button">Dashboard</button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row text-center ml-0">
          <div
            className="dashboard-card number-chart mx-2 shadow-sm  bg-whiterounded cardhead"
            style={{
              width: "48%",
              height: "auto",
              backgroundColor: "white",
              border: "1px solid lightgray",
            }}
          >
            <div className="body">
            <div className="additional-row1">
                <span style={{ color: "black", fontSize: "18px" }} className="">
                  Total Receivables
                </span>
                <div className="relative inline-block" ref={dropdownRef}>
                  <button className="new-btn" onClick={toggleDropdown}>
                    <AddCircleIcon /> New
                  </button>

                  {isDropdownOpen && (
                    <div
                      style={{
                        position: "absolute",
                        right: 663,
                        marginTop: "8px",
                        width: "auto",
                        backgroundColor: "white",
                        borderRadius: "4px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                        border: "1px solid #e5e7eb",
                        zIndex: 1000,
                      }}
                    >
                      <a
                        href="/InvoicesNew"
                        style={{
                          display: "block",
                          padding: "8px 16px",
                          margin: "2px",
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        className="hover-highlight"
                      >
                        <AddCircleIcon /> New Invoice
                      </a>
                      <a
                        href="/RecurringInvoiceNew"
                        style={{
                          display: "block",
                          padding: "8px 16px",
                          margin: "2px",
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        className="hover-highlight"
                      >
                        <AddCircleIcon /> New Recurring Invoice
                      </a>
                      <a
                        href="/PaymentRecivedNew"
                        style={{
                          display: "block",
                          padding: "8px 16px",
                          margin: "2px",
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        className="hover-highlight"
                      >
                        <AddCircleIcon /> New Customer Payment
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <br />

              <div className="body col-md-12 mt-60 addtional-row2">
                <ul className="list-unstyled">
                  <li>
                    <h6 className="d-flex justify-content-between align-items-center">
                      <span className="text-muted">
                        Total unpaid invoices 
                      </span>
                      <span className="text-muted font-14">
                        {isNaN(currentPercentage) ? 0 : currentPercentage} %{" "}
                      </span>
                    </h6>
                    <div className="mb-3">
                      <ProgressBar
                        now={isNaN(currentPercentage) ? 0 : currentPercentage}
                        height="50px"
                        className="progress-xs progress-transparent custom-color-green"
                        
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className="additional-row  text-uppercase"
                style={{ color: "black" }}
              >
                <div className="row mt-0 addtional-row3">
                  <div className="col-md-5 current-column">
                    <span
                      style={{
                        color: "MediumSeaGreen",
                        fontSize: "16px",
                        marginLeft:"-45px",
                        paddingBottom: "5px",
                      }}
                      className="text-left"
                    >
                      CURRENT
                    </span>
                    {" "}
                    <p style={{ marginTop: "5px",marginLeft:"-45px", fontSize: "16px" }}>
                      {isNaN(currentValue) ? 0 : currentValue}{" "}
                    </p>{" "}
                  </div>
                  {/* Vertical Line */}
                  <div className="vertical-line"></div>
                  <div className="col-md-5">
                    <span
                      style={{ color: "red", fontSize: "16px" }}
                      className="text-center"
                    >
                      OverDue
                    </span>{" "}
                    <p style={{ margin: "5px 0", fontSize: "16px" }}>
                      {isNaN(overValue) ? 0 : overValue}
                      <span
                        className="dropdown-arrow"
                        onClick={toggleDropdown4}
                      >
                        ▼
                      </span>
                      {isDropdownOpen4 && (
                          <div
                          ref={dropdownRef4}
                          style={{
                            position: "absolute",
                            bottom: "100%",
                            marginBottom: "8px",
                            width: "220px",
                            backgroundColor: "white",
                            borderRadius: "4px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                            border: "1px solid #e5e7eb",
                            zIndex: 1000,
                          }}
                        >
                          <a
                                    href=""
                                    style={{
                                      display: "block",
                                      padding: "8px 16px",
                                      margin: "2px",
                                      color: "#007bff",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      display: "flex",
                                      justifyContent : "space-between",
                                    }}
                                    className="hover-highlight"
                                  >
                                    {/* <AddCircleIcon /> New Invoice */}
                                    <p>1-15 days</p>
                                    <p>12.06</p>
                                  </a>
                                  <a
                                    href=""
                                    style={{
                                      display: "block",
                                      padding: "8px 16px",
                                      margin: "2px",
                                      color: "#007bff",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      display: "flex",
                                      justifyContent : "space-between",
                                    }}
                                    className="hover-highlight"
                                  >
                                    <p>15-30 days</p>
                                    <p>12.06</p>
                                  </a>
                                  <a
                                    href=""
                                    style={{
                                      display: "block",
                                      padding: "8px 16px",
                                      margin: "2px",
                                      color: "#007bff",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      display: "flex",
                                      justifyContent : "space-between",
                                    }}
                                    className="hover-highlight"
                                  >
                                    <p>Above 30 days</p>
                                    <p>12.06</p>
                                  </a>
                                  
                        </div>
                        
                      )}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="dashboard-card number-chart mx-2 shadow-sm  bg-whiterounded cardhead"
            style={{
              width: "48%",
              height: "auto",
              backgroundColor: "white",
              border: "1px solid lightgray",
            }}
          >
            <div className="body">
              <div className="additional-row1 ">
                {/* <div className="row text-center pt-10">
                  <div className="col-md-6 ml-3 mt-4 text-left"> */}
                    {" "}
                    <span
                      style={{ color: "black", fontSize: "18px" }}
                      className=""
                    >
                      Total Payables
                    </span>
                    <div className="relative inline-block" ref={dropdownRef2}>
                  <button className="new-btn" onClick={toggleDropdown2}>
                    <AddCircleIcon /> New
                  </button>
                  {isDropdownOpen2 && (
                    <div
                      style={{
                        position: "absolute",
                        right: 45,
                        marginTop: "8px",
                        width: "auto",
                        backgroundColor: "white",
                        borderRadius: "4px",
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                        border: "1px solid #e5e7eb",
                        zIndex: 1000,
                      }}
                    >
                      <a
                        href="/BillsNew"
                        style={{
                          display: "block",
                          padding: "8px 16px",
                          margin: "2px",
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        className="hover-highlight"
                      >
                        <AddCircleIcon /> New Bill
                      </a>
                      <a
                        href="/PaymentsMadeNew"
                        style={{
                          display: "block",
                          padding: "8px 16px",
                          margin: "2px",
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        className="hover-highlight"
                      >
                        <AddCircleIcon /> New Vendor Payment
                      </a>
                      <a
                        href="/PurchaseOrderNew"
                        style={{
                          display: "block",
                          padding: "8px 16px",
                          margin: "2px",
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                        className="hover-highlight"
                      >
                        <AddCircleIcon /> New Purchase Order
                      </a>
                    </div>
                  )}
                </div>
                  
                  {/* </div>
                </div> */}
              </div>
              <br />

              <div className="body col-md-12 mt-60 addtional-row2">
                <ul className="list-unstyled">
                  <li>
                    <h6 className="d-flex justify-content-between align-items-center">
                      <span className="text-muted">Total unpaid bills </span>
                      <span className="text-muted font-14">
                        {isNaN(currentPercentageBill)
                          ? 0
                          : currentPercentageBill}{" "}
                        %{" "}
                      </span>
                    </h6>
                    <div className="mb-3">
                      <ProgressBar
                        now={
                          isNaN(currentPercentageBill)
                            ? 0
                            : currentPercentageBill
                        }
                        className="progress-xs progress-transparent custom-color-orange"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className="additional-row mt-0 text-uppercase"
                style={{ color: "black" }}
              >
                <div className=" mt-0 addtional-row33">
                  <div className="col-md-5 current-column">
                    <span
                      style={{
                        color: "MediumSeaGreen",
                        fontSize: "16px",
                        marginLeft:"-60px",
                        paddingBottom: "5px",
                      }}
                      className="text-left"
                    >
                      CURRENT
                    </span>{" "}
                    <p style={{ marginLeft: "-60px",marginTop:"5px", fontSize: "16px" }}>
                      {isNaN(currentValueBill) ? 0 : currentValueBill}
                    </p>{" "}
                  </div>

                  <div className="col-md-5">
                    <span
                      style={{ color: "red", fontSize: "16px" }}
                      className="text-center"
                    >
                      OverDue
                    </span>{" "}
                    <p style={{ margin: "5px 0", fontSize: "16px" }}>
                      {isNaN(overValueBill) ? 0 : overValueBill}
                      <span
                        className="dropdown-arrow"
                        onClick={toggleDropdown3}
                      >
                        ▼
                      </span>
                      {isDropdownOpen3 && (
                        <div
                          ref={dropdownRef3}
                          style={{
                            position: "absolute",
                            bottom: "100%",
                            marginBottom: "8px",
                            width: "220px",
                            backgroundColor: "white",
                            borderRadius: "4px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                            border: "1px solid #e5e7eb",
                            zIndex: 1000,
                          }}
                        >
                          <a
                                    href=""
                                    style={{
                                      display: "block",
                                      padding: "8px 16px",
                                      margin: "2px",
                                      color: "#007bff",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      display: "flex",
                                      justifyContent : "space-between",
                                    }}
                                    className="hover-highlight"
                                  >
                                    {/* <AddCircleIcon /> New Invoice */}
                                    <p>1-15 days</p>
                                    <p>12.06</p>
                                  </a>
                                  <a
                                    href=""
                                    style={{
                                      display: "block",
                                      padding: "8px 16px",
                                      margin: "2px",
                                      color: "#007bff",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      display: "flex",
                                      justifyContent : "space-between",
                                    }}
                                    className="hover-highlight"
                                  >
                                    <p>16-30 days</p>
                                    <p>12.06</p>
                                  </a>
                                  <a
                                    href=""
                                    style={{
                                      display: "block",
                                      padding: "8px 16px",
                                      margin: "2px",
                                      color: "#007bff",
                                      textDecoration: "none",
                                      fontSize: "14px",
                                      textAlign: "left",
                                      display: "flex",
                                      justifyContent : "space-between",
                                    }}
                                    className="hover-highlight"
                                  >
                                    <p>Above 30 days</p>
                                    <p>12.06</p>
                                  </a>
                                  
                        </div>
                      )}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row ml-2">
          <div
            className="card number-chart  count-card shadow-sm  bg-white  rounded"
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Invoice
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "auto",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2">{invoiceCount}</h4>
              </div>
            </div>
          </div>
          <div
            className="card number-chart mx-2 count-card shadow-sm  bg-white rounded"
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Purchase
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "end",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2"> {purchaseCount}</h4>
              </div>
            </div>
          </div>

          <div
            className="card number-chart  count-card shadow-sm  bg-white rounded "
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Item
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "auto",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2">{itemCount}</h4>
              </div>
            </div>
          </div>
          <div
            className="card number-chart mx-2  count-card shadow-sm  bg-white rounded"
            style={{
              width: "24%",
              height: "110px",
              border: "solid 1px rgba(0,0,0,0.1)",
            }}
          >
            <div
              className="body"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    paddingBottom: "5px",
                  }}
                  className="text-left"
                >
                  Customer
                </span>{" "}
              </div>
              <div
                style={{
                  textAlign: "right",
                  marginTop: "auto",
                  fontSize: "16px",
                }}
              >
                <h4 className="mb-0 mt-2">{customerCount}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix row-deck">
        <div className="col-lg-12">
          <div className="card dashboard-card">
            <CashFlowChart lineChartData={lineChartData} />
          </div>
        </div>
      </div>
      <div className="row clearfix row-deck">
        <GoogleAnalyticCard />
      </div>
    </>
  );
};

export default Analytical;
